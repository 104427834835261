<template>
  <div class="main-wrapper">
    <el-breadcrumb separator=">" class="genera-breadcrumb">
      <el-breadcrumb-item>实训中心</el-breadcrumb-item>
      <el-breadcrumb-item>创建实训</el-breadcrumb-item>
    </el-breadcrumb>
    <div style="margin-top: 40px">
      <span
        style="
          display: inline-block;
          width: 6px;
          height: 12px;
          margin-right: 10px;
          background-color: rgb(40, 33, 252);
          vertical-align: middle;
          border-radius: 3px;
        "
      ></span>
      <span style="font-size: 16px; font-weight: bold; vertical-align: middle"
        >创建实训</span
      >
    </div>
    <div class="content">
      <el-form
        :model="addDrillForm"
        :rules="rules"
        ref="addDrillForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="实训名称" prop="name">
          <el-input
            v-model="addDrillForm.name"
            style="width: 350px"
            placeholder="2-20个字符"
          ></el-input>
        </el-form-item>
        <el-form-item label="实训题试卷" prop="paperOption">
          <el-select
            v-model="addDrillForm.paperOption"
            clearable
            value-key="name"
            style="width: 350px"
            placeholder="请选择"
            @change="PaperFun"
          >
            <el-option
              v-for="item in options"
              :key="item.school_paper_id"
              :value="item.school_paper_id"
              :label="item.paper_name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="直播时长" >
          {{ this.duration_minute }}分{{ this.duration_second }}秒
        </el-form-item>
        <el-form-item label="实训时间" prop="time">
          <el-date-picker
            v-model="addDrillForm.time"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="班级" prop="grade">
          <el-select
            v-model="addDrillForm.grade"
            clearable
            value-key="name"
            style="width: 350px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in gradeOptions"
              :key="item.banji_id"
              :value="item.banji_id"
              :label="item.banji_name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div class="next-btn">
      <el-button @click="goToBack">返回</el-button>

      <el-button type="primary" v-if="this.$route.query.id" @click="save"
        >保存</el-button
      >
      <el-button type="primary" v-else @click="save">创建</el-button>
    </div>
  </div>
</template>

<script>
import {
  getClassListData,
  getSchoolPaper,
  getScriptTime,
  practicalSave,
} from "@/utils/apis";
export default {
  data() {
    return {
      addDrillForm: {
        name: "",
        paperOption: "",
        time: "",
        grade: "",
      },
      options: [
        {
          id: 1,
          name: "试卷1",
          value: 1,
        },
      ],
      gradeOptions: [],
      num_id: "",
      duration_minute: 0,
      duration_second: 0,
    
      rules: {
        name: [
          { required: true, message: "请输入实训名称", trigger: "blur" },
          { min: 2, max: 20, message: "长度在2到20个字符", trigger: "blur" },
        ],
        paperOption: [
          { required: true, message: "请选择实训题试卷", trigger: "change" },
        ],
        time: [
          { required: true, message: "请选择实训时间", trigger: "blur" },
        ],
        grade: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
      },
      
      pickerOptions: {
        // shortcuts: [
        //   {
        //     text: "最近一周",
        //     onClick(picker) {
        //       const end = new Date();
        //       const start = new Date();
        //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        //       picker.$emit("pick", [start, end]);
        //     },
        //   },
        //   {
        //     text: "最近一个月",
        //     onClick(picker) {
        //       const end = new Date();
        //       const start = new Date();
        //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        //       picker.$emit("pick", [start, end]);
        //     },
        //   },
        //   {
        //     text: "最近三个月",
        //     onClick(picker) {
        //       const end = new Date();
        //       const start = new Date();
        //       start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        //       picker.$emit("pick", [start, end]);
        //     },
        //   },
        // ],
      },
    };
  },
  created() {
    this.getGgradeList();
    this.optionFun();
    this.editEstablish();
  },
  methods: {
    getGgradeList() {
      let param = {
        paging: 0,
      };
      getClassListData(param)
        .then((res) => {
          this.gradeOptions = res.data.list;
        })
        .catch((err) => {
          console.error("err", err);
        });
    },
    //编辑
    editEstablish() {
      if (this.$route.query.id) {
        this.num_id = this.$route.query.id;
        this.addDrillForm.name = this.$route.query.data.train_name;
        this.addDrillForm.grade = this.$route.query.data.class_id;
        let arr = [];
        arr.push(
          this.$route.query.data.start_time,
          this.$route.query.data.end_time
        );
        this.addDrillForm.time = arr;
        this.addDrillForm.paperOption = this.$route.query.data.school_paper_id;
        this.PaperFun();
      }
    },

    //获取试卷
    optionFun() {
      getSchoolPaper()
        .then((res) => {
          this.options = res.data;
        })
        .catch((err) => {
          console.error("err", err);
        });
    },
    //试卷选择
    PaperFun() {
      let param = {
        school_paper_id: this.addDrillForm.paperOption,
      };
      getScriptTime(param)
        .then((res) => {
          this.duration_minute = Math.floor(res.data.total_time / 60);
          this.duration_second = Math.floor(res.data.total_time % 60);
        })
        .catch((err) => {
          console.error("err", err);
        });
    },
    //返回
    goToBack() {
      this.$router.push({
        path: "/school/practical/index",
      });
    },
    //创建
    save() {
      this.$refs["addDrillForm"].validate((valid) => {
        if (valid) {
          
          let param = {
            id: this.num_id,
            train_name: this.addDrillForm.name, //实训名称
            class_id: this.addDrillForm.grade, //班级ID
            start_time: this.addDrillForm.time[0], //开始时间
            end_time: this.addDrillForm.time[1], //结束时间
            school_paper_id: this.addDrillForm.paperOption, //试卷ID
          };
          // console.log(this.$route.query.status,'9999')
          if(this.$route.query.status ==3){
            param.id =''
          }

          practicalSave(param)
            .then((res) => {
              if (res.code == 200) {
                if (param.id != "") {
                  this.$message.success("编辑成功");
                } else {
                  this.$message.success("创建成功");
                }

                this.$router.push({
                  path: "/school/practical/index",
                });
              }
            })
            .catch((err) => {
              console.error("err", err);
            });
        }
      });
    },
  },
};
</script>



<style lang="scss"  scoped>
.main-wrapper {
  height: 100%;
  padding: 30px 30px 0;
  .content {
    padding: 50px;
  }
  .next-btn {
    text-align: center;
  }
}
</style>